export const companiesWords = {
  wodosl: {
    company_id: "Worldnet-Documents-SL",
    company_name: "Worldnet Documents SL",
    company_vat: "B67515262",
    address_1: "Av. Litoral 12, 5ª, 08005 Barcelona, Spain",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}, in volume {{volume_number}}, section {{section_number}}, sheet {{sheet_number}}, registration {{registration_number}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}, en el volumen {{volume_number}}, sección {{section_number}}, hoja {{sheet_number}}, registro {{registration_number}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "Barcelona",
    footer_name_company_1: "Worldnet Documents SL",
    footer_direction: "Av. Litoral 12, 5ª, 08005 Barcelona, Spain",
    footer_vat: "B67515262",
    volume_number: `47086`,
    section_number: `145`,
    sheet_number: `540666`,
    registration_number: `1`,
    site_phone: `n/a`,
    footer_name_company_1_text: `Worldnet Documents SL`,
    footer_vat_text: `B67515262`,
    footer_direction_text: `Av. Litoral 12, 5ª, 08005 Barcelona, Spain`,
  },
  taexsl: {
    company_name: "Tangula Expert SL",
    company_vat: "B67488767",
    address_1: "Gran Via Carles III, num. 98, 6º 08028, Barcelona, Spain",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}, in volume {{volume_number}}, section {{section_number}}, sheet {{sheet_number}}, registration {{registration_number}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}, en el volumen {{volume_number}}, sección {{section_number}}, hoja {{sheet_number}}, registro {{registration_number}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "Barcelona",
    footer_name_company_1: "Tangula Expert SL",
    footer_direction: "Gran Via Carles III, num. 98, 6º 08028, Barcelona, Spain",
    footer_vat: "B67488767",
    volume_number: `47025`,
    section_number: `62`,
    sheet_number: `538544`,
    registration_number: `1`,
    footer_name_company_1_text: `Tangula Expert SL`,
    footer_vat_text: `B67488767`,
    footer_direction_text: `Gran Via Carles III, num. 98, 6º 08028, Barcelona, Spain`,
  },
  scstsl: {
    company_name: "Scene Strokes SL",
    company_vat: "B67481663",
    address_1: "Calle Pallars 194, 8ª, 08005, Barcelona, Spain",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}, in volume {{volume_number}}, section {{section_number}}, sheet {{sheet_number}}, registration {{registration_number}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}, en el volumen {{volume_number}}, sección {{section_number}}, hoja {{sheet_number}}, registro {{registration_number}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "Barcelona",
    footer_name_company_1: "Scene Strokes SL",
    footer_direction: "Calle Pallars 194, 8ª, 08005, Barcelona, Spain",
    footer_vat: "B67481663",
    volume_number: `47021`,
    section_number: `177`,
    sheet_number: `538453`,
    registration_number: `1`,
    site_phone: `n/a`,
    footer_name_company_1_text: `Scene Strokes SL`,
    footer_vat_text: `B67481663`,
    footer_direction_text: `Calle Pallars 194, 8ª, 08005, Barcelona, Spain`,
  },
  sabusl: {
    company_name: "Saovine Business SL",
    company_vat: "B01598838",
    address_1: "Via Augusta, num. 29, 6º 08006, Barcelona, Spain",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}, in volume {{volume_number}}, section {{section_number}}, sheet {{sheet_number}}, registration {{registration_number}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}, en el volumen {{volume_number}}, sección {{section_number}}, hoja {{sheet_number}}, registro {{registration_number}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "Barcelona",
    footer_name_company_1: "Saovine Business SL",
    footer_direction: "Via Augusta, num. 29, 6º 08006, Barcelona, Spain",
    footer_vat: "B01598838",
    volume_number: `47345`,
    section_number: `113`,
    sheet_number: `459.179`,
    registration_number: `1`,
    site_phone: `n/a`,
    footer_name_company_1_text: `Saovine Business SL`,
    footer_vat_text: `B01598838`,
    footer_direction_text: `Via Augusta, num. 29, 6º 08006, Barcelona, Spain`,
  },
  mu50sl: {
    company_name: "Mursin 5000 SL",
    company_vat: "B01756006",
    address_1: "Av. Litoral s/n Portal 12, planta 5 08005, Barcelona, Spain",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}, in volume {{volume_number}}, section {{section_number}}, sheet {{sheet_number}}, registration {{registration_number}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}, en el volumen {{volume_number}}, sección {{section_number}}, hoja {{sheet_number}}, registro {{registration_number}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "Barcelona",
    footer_name_company_1: "Mursin 5000 SL",
    footer_direction: "Av. Litoral s/n Portal 12, planta 5 08005, Barcelona, Spain",
    footer_vat: "B01756006",
    volume_number: `47412`,
    section_number: `59`,
    sheet_number: `551032`,
    registration_number: `1`,
    site_phone: `n/a`,
    footer_name_company_1_text: "Mursin 5000 SL",
    footer_vat_text: "B01756006",
    footer_direction_text: "Av. Litoral s/n Portal 12, planta 5 08005, Barcelona, Spain",
  },
  fogell: {
    company_name: "Form Genius LLC",
    company_vat: "L16000180466",
    address_1: "2655 Le Jeune Road, Suite 716, Coral Gables, Florida 33134, United States",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, with number of inscription {{company_vat}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}.`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, con número de inscripción {{company_vat}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "-",
    footer_name_company_1: "Form Genius LLC",
    footer_direction: "2655 Le Jeune Road, Suite 716, Coral Gables, Florida 33134, United States",
    footer_vat: "L16000180466",
    site_phone: `+1 (786) 2091728`,
    volume_number: ``,
    section_number: ``,
    sheet_number: ``,
    registration_number: ``,
    footer_name_company_1_text: `Form Genius LLC`,
    footer_vat_text: `L16000180466`,
    footer_direction_text: `2655 Le Jeune Road, Suite 716, Coral Gables, Florida 33134, United States`,
  },
  mesosl: {
    company_name: "Menturis Solution SL",
    company_vat: "B67340711",
    address_1: "Rb Catalunya 125, planta 3 Pta.2  08008, Barcelona, Spain",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}, in volume {{volume_number}}, section {{section_number}}, sheet {{sheet_number}}, registration {{registration_number}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}.`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}, en el volumen {{volume_number}}, sección {{section_number}}, hoja {{sheet_number}}, registro {{registration_number}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "Barcelona",
    footer_name_company_1: "Menturis Solution SL",
    footer_direction: "Rb Catalunya 125, planta 3 Pta.2  08008, Barcelona, Spain",
    footer_vat: "B67340711",
    volume_number: `46692`,
    section_number: `190`,
    sheet_number: `527927`,
    registration_number: `1`,
    site_phone: `n/a`,
    footer_name_company_1_text: `Menturis Solution SL`,
    footer_vat_text: `B67340711`,
    footer_direction_text: `Rb Catalunya 125, planta 3 Pta.2  08008, Barcelona, Spain`,
  },
  nitrsl: {
    company_name: "Nipi Trade SL",
    company_vat: "B05454491",
    address_1: "Carrer Transversal 226, planta baja, 08225 Terrassa, Spain",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}, in volume {{volume_number}}, section {{section_number}}, sheet {{sheet_number}}, registration {{registration_number}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}, en el volumen {{volume_number}}, sección {{section_number}}, hoja {{sheet_number}}, registro {{registration_number}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "Barcelona",
    footer_name_company_1: "Nipi Trade SL",
    footer_direction: "Carrer Transversal 226, planta baja, 08225 Terrassa, Spain",
    footer_vat: "B05454491",
    volume_number: `29687`,
    section_number: `166`,
    sheet_number: `B-159.898`,
    registration_number: `1`,
    site_phone: `n/a`,
    footer_name_company_1_text: `Nipi Trade SL`,
    footer_vat_text: `B05454491`,
    footer_direction_text: `Carrer Transversal 226, planta baja, 08225 Terrassa, Spain`,
  },
  matropsell: {
    company_name: "Macro Trade Op Services LLC",
    company_vat: "L21000494577",
    address_1: "6664 Stirling road, Hollywood 33024 Florida, United States",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, with number of inscription {{company_vat}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}, inscribed in the Mercantile Registry of {{registry_city}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}, inscrito en el registro mercantil de {{registry_city}}`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, con número de inscripción {{company_vat}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "-",
    footer_name_company_1: "Macro Trade Op Services LLC",
    footer_direction: "6664 Stirling road, Hollywood 33024 Florida, United States",
    footer_vat: "L21000494577",
    volume_number: `-`,
    section_number: `-`,
    sheet_number: `-`,
    registration_number: `-`,
    site_phone: `n/a`,
    footer_name_company_1_text: `Macro Trade Op Services LLC`,
    footer_vat_text: `L21000494577`,
    footer_direction_text: `6664 Stirling road, Hollywood 33024 Florida, United States`,
  },
  hogcopsell: {
    company_name: "Holding Gc Op Services LLC",
    company_vat: "L21000498468",
    address_1: "6664 Stirling road, Hollywood 33024 Florida, United States",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, with number of inscription {{company_vat}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}.`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, con número de inscripción {{company_vat}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "-",
    footer_name_company_1: "Holding Gc Op Services LLC",
    footer_direction: "6664 Stirling road, Hollywood 33024 Florida, United States",
    footer_vat: "L21000498468",
    volume_number: `-`,
    section_number: `-`,
    sheet_number: `-`,
    registration_number: `-`,
    site_phone: `n/a`,
    footer_name_company_1_text: `Holding Gc Op Services LLC`,
    footer_vat_text: `L21000498468`,
    footer_direction_text: `6664 Stirling road, Hollywood 33024 Florida, United States`,
  },
  propsell: {
    company_name: "Premium Op Services LLC",
    company_vat: "L21000493413",
    address_1: "2598 E. Sunrise Blvd, 2104 Fort Lauderdale, Florida 33024, United States",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, with number of inscription {{company_vat}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}.`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, con número de inscripción {{company_vat}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "-",
    footer_name_company_1: "Premium Op Services LLC",
    footer_direction: "2598 E. Sunrise Blvd, 2104 Fort Lauderdale, Florida 33024, United States",
    footer_vat: "L21000493413",
    volume_number: `-`,
    section_number: `-`,
    sheet_number: `-`,
    registration_number: `-`,
    site_phone: `n/a`,
    footer_name_company_1_text: `Premium Op Services LLC`,
    footer_vat_text: `L21000493413`,
    footer_direction_text: `2598 E. Sunrise Blvd, 2104 Fort Lauderdale, Florida 33024, United States`,
  },
  inopsell: {
    company_name: "Inter Op Services LLC",
    company_vat: "L21000495197",
    address_1: "700 S. Rosermary Ave, Suite 204, West Palm Beach, FL 33401, United States",
    registry_city: "-",
    footer_name_company_1: "Inter Op Services LLCC",
    footer_direction: "700 S. Rosermary Ave, Suite 204, West Palm Beach, FL 33401, United States",
    footer_vat: "L21000495197",
    volume_number: `-`,
    section_number: `-`,
    sheet_number: `-`,
    registration_number: `-`,
    site_phone: `n/a`,
    footer_name_company_1_text: `Inter Op Services LLC`,
    footer_vat_text: `L21000495197`,
    footer_direction_text: `700 S. Rosermary Ave, Suite 204, West Palm Beach, FL 33401, United States`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}.`,
  },
  onimsldbpaanvi: {
    company_name: "Online Immigration SL dba Passports and Visas",
    company_vat: "L21000495197",
    address_1: "2232 Dell Range Blvd., Suite 245, Cheyenne, WY 82009, United States",
    about_our_services: `The present website is owned and operated by {{company_name}}, a company with TAX ID number {{company_vat}}, with number of inscription {{company_vat}}, and registered office at {{address_1}} and contact email {{web_mail}} (hereinafter, “the Company”).`,
    merchant_of_record_info: `{{company_name}}, a company with TAX ID number {{company_vat}}.`,
    merchant_of_record_info_es: `{{company_name}}, una empresa con número de CUIT {{company_vat}}.`,
    about_our_services_es: `La gestión y propiedad del presente sitio web corresponden a {{company_name}}, una empresa con número de CUIT {{company_vat}}, con número de inscripción {{company_vat}}, con domicilio fiscal en {{address_1}} y correo electrónico de contacto {{web_mail}} (en adelante, «la Empresa»).`,
    registry_city: "-",
    footer_name_company_1: "Online Immigration SL dba Passports and Visas",
    footer_direction: "2232 Dell Range Blvd., Suite 245, Cheyenne, WY 82009, United States",
    footer_vat: "L21000495197",
    volume_number: `-`,
    section_number: `-`,
    sheet_number: `-`,
    registration_number: `-`,
    site_phone: `+1 833 947 2842`,
    footer_name_company_1_text: `Online Immigration SL dba Passports and Visas`,
    footer_vat_text: "L21000495197",
    footer_direction_text: `2232 Dell Range Blvd., Suite 245, Cheyenne, WY 82009, United States`,
  },
};
